import { Alert, AlertColor, Snackbar } from '@mui/material/';

const ToastAlert = ({
  alertValues,
  handleAlertClose,
}: {
  alertValues: {
    showAlert: boolean;
    type: AlertColor;
    message: string;
  };
  handleAlertClose: () => void;
}) => {
  return (
    <>
      <Snackbar
        open={alertValues.showAlert}
        autoHideDuration={5000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={handleAlertClose}
          severity={alertValues.type}
          sx={{ width: '100%' }}
        >
          {alertValues.message}
        </Alert>
      </Snackbar>
    </>
  );
};
export default ToastAlert;
