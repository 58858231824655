import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Radio,
  Typography,
} from '@mui/material';
import { DataGrid, GridColDef, GridSelectionModel } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';

import { fetchData } from '../../lib/fetchData';

const Scenario = ({ onSelect, systemConfig, state, scenarios }) => {
  const [selectedRowIds, setSelectedRowIds] = useState<GridSelectionModel>([]);
  const [selectedScenario, setSelectedScenario] = useState<any>();
  const [openScenarioSelection, setOpenScenarioSelection] = useState(false);
  const columns: GridColDef[] = [
    {
      field: 'radiobutton',
      headerName: '',
      width: 50,
      sortable: false,
      renderCell: (params) => (
        <Radio checked={selectedRowIds[0] === params.id} value={params.id} />
      ),
    },
    { field: 'scenarioId', headerName: 'ScenarioID', width: 100 },
    { field: 'scenarioName', headerName: 'Scenario Name', width: 200 },
    {
      field: 'scenarioDescription',
      headerName: 'Scenario Description',
      width: 250,
    },
    { field: 'portfolioName', headerName: 'Portfolio Name', width: 200 },
    {
      field: 'portfolioDescription',
      headerName: 'Portfolio Description',
      width: 250,
    },
    {
      field: 'chartOfAccountsName',
      headerName: 'ChartOfAccountsName',
      width: 150,
    },
  ];

  useEffect(() => {
    if (scenarios && systemConfig && systemConfig.integrationScenarioID) {
      let index = scenarios.findIndex(
        (x) => x.integrationId === systemConfig.integrationScenarioID,
      );
      if (index < 0) {
        return;
      }
      setSelectedScenario(scenarios[index]);
      setSelectedRowIds([index]);
    }
  }, [scenarios, systemConfig]);

  const onDoneClick = () => {
    let s = scenarios[selectedRowIds[0]];
    setSelectedScenario(s);
    if (s) {
      onSelect(s.scenarioId);
    }
    setOpenScenarioSelection(false);
  };

  const getScenarioName = () => {
    const scenarioId = selectedScenario?.scenarioId
      ? selectedScenario?.scenarioId
      : systemConfig?.integrationScenarioID
      ? systemConfig?.integrationScenarioID
      : '';

    const scenarioName = scenarios.find(
      (x) => x.scenarioId === scenarioId,
    )?.scenarioName;

    return 'IntegrationScenarioId: ' + scenarioId + ' (' + scenarioName + ')';
  };

  return (
    <>
      <Typography
        variant="subtitle1"
        style={{ textDecorationLine: 'underline', fontWeight: 'bold' }}
      >
        Scenario:
        <Button
          style={{
            margin: '0 0 10px 10px',
            height: '20px',
            backgroundColor: 'gray',
          }}
          variant="contained"
          size="small"
          color="secondary"
          onClick={() => setOpenScenarioSelection(true)}
        >
          Modify
        </Button>
      </Typography>
      {/* {selectedRowIds?.length > 0 && selectedScenario && ( */}
      <>
        <Typography
          variant="body1"
          textAlign={'center'}
          style={{ backgroundColor: 'lightgrey', maxWidth: '385px' }}
        >
          {getScenarioName()}
        </Typography>
      </>
      {/* )} */}

      <Dialog open={openScenarioSelection} fullWidth={true} maxWidth={'lg'}>
        <DialogTitle>Select Scenarios/Portfolios</DialogTitle>
        <DialogContent>
          <div style={{ height: 400, width: '100%' }}>
            <DataGrid
              selectionModel={selectedRowIds}
              onSelectionModelChange={setSelectedRowIds}
              rows={scenarios.map((p, i) => ({
                id: i,
                scenarioId: p.scenarioId,
                scenarioName: p.scenarioName,
                scenarioDescription: p.scenarioDescription,
                portfolioName: p.portfolioName,
                portfolioDescription: p.portfolioDescription,
                chartOfAccountsName: p.chartOfAccountsName,
              }))}
              columns={columns}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              onDoneClick();
            }}
          >
            Done
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              setOpenScenarioSelection(false);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Scenario;
